import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyBcj6LBwqhIvp-7ljQXLYSrttoJypxzahk',
    authDomain: 'fitwohnen.firebaseapp.com',
    projectId: 'fitwohnen',
    storageBucket: 'fitwohnen.appspot.com',
    messagingSenderId: '607739460834',
    appId: '1:607739460834:web:7fb6af0ff682bc962132bb',
};

let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig);
} else {
    app = firebase.app();
}

let pushToken;
const db = app.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage, firebaseConfig };
